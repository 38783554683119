<template>
  <div class="acervo_publicacoes">
    <section class="container d-flex justify-center align-center">
      <v-row class="d-flex justify-center align-stretch">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="d-flex justify-center align-center pos-relative">
            <Card borderColor="#C92F05" class="">
              <template v-slot:conteudo>
                <v-row class="d-flex flex-column justify-center align-center">
                  <v-col class="d-flex justify-center align-center">
                    <img src="/logos/logo-caritas-main-preto.png" alt="" />
                  </v-col>
                  <v-col class="d-flex justify-center align-center flex-column">
                    <h3 style="color: #C92F05">Biblioteca</h3>
                    <p style="text-align: center; width: 80%;">
                      Ao clicar no link abaixo, você será direcionado para a
                      biblioteca da Cáritas Regional Minas Gerais e terá acesso
                      ao acervo de publicações.
                    </p>
                    <p style="margin-bottom: 0">
                      Link de acesso a Cáritas - Biblioteca
                    </p>
                    <p style="margin-bottom: 0">
                      <span class="pink--text"
                        ><a
                          class="pink--text"
                          style="text-decoration: none;"
                          href="http://mg.caritas.org.br/biblioteca"
                          target="_blank"
                          >http://mg.caritas.org.br/biblioteca</a
                        ></span
                      >
                    </p>
                  </v-col>
                </v-row>
              </template>
            </Card>
            <img
              width="20%"
              class="book_image"
              src="../assets/book.png"
              alt=""
            />
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: { Card },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #dd92ba;
}

.acervo_publicacoes {
  height: 100%;
  width: 100%;
  background-image: url("../assets/acervo_publicacoes_bg.png");
  background-size: cover;
  display: flex;
}

.pos-relative {
  position: relative;
}

.book_image {
  position: absolute;
  bottom: -10%;
  left: -12%;
}

@media (max-width: 950px) {
  .book_image {
    position: absolute;
    bottom: -10%;
    left: -4%;
  }
}
</style>
